import React, {Component} from 'react';
import axios from "axios";
import background from "../../../assets/backgrounds/backgroundCompte.jpg";
import {bannerSelect} from "../modules/Helper.jsx";
import playlistDefault from "../../../assets/images/5.png";
import play_compte from "../../../assets/logos/play_compte.png";
import {Link} from "react-router-dom";
import repac from "../../../assets/logos/repac.svg";
import reposts from "../../../assets/logos/reposts.svg";
import playlist from "../../../assets/logos/playlist.svg";
import instant from "../../../assets/logos/instant.svg";
import message from "../../../assets/logos/message.svg";
import messageen from "../../../assets/logos/en/messageen.svg";
import suivre from "../../../assets/logos/suivre.svg";
import suivie from "../../../assets/logos/suivie.svg";
import followed from "../../../assets/logos/en/followed.svg";
import dot from "../../../assets/logos/dot_horizontal.svg";
import EpingleUser from "../modules/EpingleUser";
import SmallModal from "../modules/SmallModal.jsx";
import Reposts from "./utilisateur/reposts.jsx";
import PlaylistOther from "./utilisateur/PlaylistOther.jsx";
import LastLike from "./utilisateur/LastLike.jsx";
import RecapPlaylist from "./utilisateur/RecapPlaylist.jsx";
import RepostsLine from "./utilisateur/RepostsLine.jsx";
import LastPlaces from "./utilisateur/LastPlaces.jsx";
import Creators from "./utilisateur/Creators.jsx";
import ModalList from "./utilisateur/ModalList.jsx";
import video from "../../../assets/logos/video.svg";
import vignette from "../../../assets/images/vignette1.jpg";
import trash from "../../../assets/logos/trash.svg";
import eye from "../../../assets/logos/eye.svg";

import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';

export default class PageOther extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            avatar: "",
            cover: "",
            userName: "",
            artisteName: "",
            biographie: "",
            selectedOption: "",
            creator_mod: false,
            creator_verif: false,
            styleImageBackGround: null,
            height: screen.height + 2,
            user: null,


            followers: 0,
            suivis: 0,
            places: 0,
            reposts: 0,
            instants: 0,

            playlists: null,
            shares: null,
            epingles: null,
            likevideo: null,
            suivie: false,

            type: "recap",
            //type: "video",
            videos: null,
            flashMessage: "",
            typeFlash: "",
            currentVideo: null,

            //MODAL
            openModalBug: false,
            openModalContenu: false,
            send: null,
            currentPlaylist: null,
            modalListFollow: false,
            modalType: "",
            lang:'en',
            trad:en,
        }
    }
    componentDidMount() {
        document.querySelector("body").classList.add("pageother");
        let id = location.pathname.split('/')[2];
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
                let url = import.meta.env.VITE_BACKEND_URL + "/follow/otheruser";
                axios.post(url, {
                        id: id
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: 'Bearer ' + this.state.user.token
                        }
                    }
                ).then(res => {
                    console.log(res.data);
                    if (res.data === "blocked") {
                        location.assign('/');
                    } else {
                        this.setState({
                            id: res.data.id,
                            avatar: res.data.personalisation.avatar,
                            cover: res.data.personalisation.cover,
                            userName: res.data.name,
                            biographie: res.data.personalisation.biographie,
                            selectedOption: res.data.personalisation.lang,
                            name: res.data.name,
                            creator_mod: res.data.creator === 1 ? true : false,
                            creator_verif: res.data.creator_verif === 1 ? true : false,
                            artisteName: res.data.nom_artiste,
                            playlists: res.data.playlists,
                            shares: res.data.share,
                            epingles: res.data.epingle,
                            likevideo: res.data.likeVideo,
                            suivie: res.data.folow !== null ? true : false,
                            suivis: res.data.suivis,
                            followers: res.data.follows,
                            places: res.data.places,
                            reposts: res.data.reposts,
                            lastPlaces: res.data.lastPlaces,
                            lastCreators: res.data.lastCreators,
                            instants: 0,
                            listSuivi: res.data.listSuivi,
                            listFollow: res.data.listFollow,
                            listFullPlaces: res.data.listFullPlaces,
                            videos: res.data.videos,
                        }, () => {
                            let urlImage = "";
                            if (res.data.personalisation.cover !== null) {
                                urlImage = `url(${import.meta.env.VITE_BACKEND_URL_IMG + res.data.personalisation.cover})`;
                            } else {
                                urlImage = `url(${background})`;
                            }
                            let content = document.querySelector('.content');
                            bannerSelect(urlImage);
                            content.classList.add('pageCreateur');

                            this.setState({
                                styleImageBackGround: urlImage
                            })

                        })
                    }
                }).catch(err => {
                    console.log(err);
                })
            });

        } else {
            location.assign('/');
        }

    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }
    handleResize = () => {
        this.setState({
            height: screen.height + 2
        })
    }
    handleOpenBoard = (type) => {
        this.setState({type});
    }
    callBackEpingle = (type, index) => {
        const {epingles} = this.state;
        if (type === "bug") {
            let send = [];
            send.push(type);
            send.push(epingles[index].type);
            if (epingles[index].type === 'places') {
                send.push(epingles[index].places.id);
            } else if (epingles[index].type === 'playlists') {
                send.push(epingles[index].playlists.id);
            } else if (epingles[index].type === 'creators') {
                send.push(epingles[index].user.id);
            } else if (epingles[index].type === 'videos') {
                send.push(epingles[index].video.id);
            }
            this.setState({openModalBug: true, openModalContenu: false, send});
        } else if (type === "contenu") {
            let send = [];
            send.push(type);
            send.push(epingles[index].type);
            if (epingles[index].type === 'places') {
                send.push(epingles[index].places.id);
            } else if (epingles[index].type === 'playlists') {
                send.push(epingles[index].playlists.id);
            } else if (epingles[index].type === 'creators') {
                send.push(epingles[index].user.id);
            } else if (epingles[index].type === 'videos') {
                send.push(epingles[index].video.id);
            }
            this.setState({openModalContenu: true, openModalBug: false, send});
        } else if (type === 'save') {
            let send = [];
            send.push(type);
            send.push(epingles[index].type);
            if (epingles[index].type === 'places') {
                send.push(epingles[index].places.id);
            } else if (epingles[index].type === 'playlists') {
                send.push(epingles[index].playlists.id);
            } else if (epingles[index].type === 'creators') {
                send.push(epingles[index].user.id);
            } else if (epingles[index].type === 'videos') {
                send.push(epingles[index].video.id);
            }
            // Faire l'enregistrement
        } else if (type === 'share') {
            let send = [];
            send.push(type);
            send.push(epingles[index].type);
            if (epingles[index].type === 'places') {
                send.push(epingles[index].places.id);
            } else if (epingles[index].type === 'playlists') {
                send.push(epingles[index].playlists.id);
            } else if (epingles[index].type === 'creators') {
                send.push(epingles[index].user.id);
            } else if (epingles[index].type === 'videos') {
                send.push(epingles[index].video.id);
            }
            // Faire l'enregistrement
        }
    }
    callBackPlaylist = (type, index) => {
        const {playlists} = this.state;
        let playlist = playlists[index];
        let send = [];
        if (type === "bug") {
            send.push(type);
            send.push("playlists");
            send.push(playlist.id);
            this.setState({send, openModalContenu: false, openModalBug: true});
        } else if (type === "contenu") {
            send.push(type);
            send.push("playlists");
            send.push(playlist.id);
            this.setState({send, openModalContenu: true, openModalBug: false});
        } else if (type === "save") {
            send.push(type);
            send.push("playlists");
            send.push(playlist.id);
            // Faire l'enregistrement
        } else if (type === "share") {
            send.push(type);
            send.push("playlists");
            send.push(playlist.id);
            // Faire l'enregistrement
        }
    }
    handleCloseModal = () => {
        this.setState({openModalBug: false, openModalContenu: false, modalListFollow: false});
    }
    saveShare = (type, index) => {

    }
    callbackSend = (message) => {
        const {send, user} = this.state;
        let url = import.meta.env.VITE_BACKEND_URL + "/simple/send";
        axios.post(url, {
            send,
            message: message,
            user
        }).then(res => {
            if (res.data) {
                this.handleCloseModal();
                this.setState({
                    typeFlash: 'success',
                    flashMessage: this.state.trad.messageCompte1,
                    send: null
                });
            }
        }).catch(err => {
            this.setState({
                openModalContenu: false,
                openModalBug: false,
                typeFlash: 'warning',
                flashMessage: this.state.trad.messageCompte2
            });
        })
    }
    handleSignalement = (type) => {
        let send = [];
        send.push(type);
        send.push(null);
        send.push(this.state.id);
        this.setState({openModalContenu: true, openModalBug: false, send});
    }
    handleOpenMenuDotUser = () => {
        let menu = document.getElementById('dotMenuUser');
        if (menu.style.display == "block") {
            menu.style.display = "none";
        } else {
            menu.style.display = "block";
        }
    }
    handleSuivre = (e, action) => {
        e.preventDefault();
        let url = import.meta.env.VITE_BACKEND_URL + "/follow/followUser";
        axios.post(url, {
            utilisateurs_id: this.state.user.user.id,
            suivre_utilisateurs_id: this.state.id,
            action: action,
        }, {
            headers: {
                Authorization: "Bearer " + this.state.user.token,
            },
        }).then(res => {
            this.setState({suivie: res.data});
        }).catch(err => {
            console.log(err);
        })
    }
    handleBloquer = () => {
        let url = import.meta.env.VITE_BACKEND_URL + "/bloquer/blocked";
        axios.post(url, {
            id: this.state.id,
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + this.state.user.token
            }
        }).then(res => {
            if (res.data) {
                location.assign('/');
            }
        }).catch(err => {
            console.log(err);
        })
    }
    handleEpingle = () => {
        let url = import.meta.env.VITE_BACKEND_URL + "/epingle/add";
        axios.post(url, {
            id: this.state.id,
            type: "creators",
        }, {
            headers: {
                Authorization: "Bearer " + this.state.user.token,
            },
        }).then(res => {
            let id = `menu_${index}`;
            let menu = document.getElementById(id);
            menu.style.display = "none";
        }).catch(err => {
            console.log(err);
        })
    }
    handleSendMessage = () => {
        let url = import.meta.env.VITE_BACKEND_URL + "/places/newPlaceProfilMessage";
        axios.post(url, {
            id: this.state.id,
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + this.state.user.token
            }
        }).then(res => {
            location.assign('/place/' + res.data.id);
        }).catch(err => {
            console.log(err);
        })
    }
    handleOpenList = (type) => {
        if (type === "follow" || type === "suivis" || type === "place") {
            this.setState({modalListFollow: true, modalType: type});
        } else {
            this.setState({type});
        }
    }
    render() {
        return (
            <>
                {
                    this.state.flashMessage !== "" &&
                    <div className={`message ${this.state.typeFlash}`}>
                        <p>{this.state.flashMessage}</p>
                    </div>
                }
                {
                    this.state.styleImageBackGround !== null &&
                    <>
                        <div className="block_presentation">
                            <div className="block_header_creator">
                                <div className="block_img">
                                    {
                                        this.state.avatar !== null ?

                                            <img
                                                className={`avatar_creator ${this.state.creator_verif ? "square" : ""}`}
                                                src={import.meta.env.VITE_BACKEND_URL_IMG + this.state.avatar} alt=""/>
                                            :
                                            <img
                                                className={`avatar_creator ${this.state.creator_verif ? "square" : ""}`}
                                                src={playlistDefault} alt=""/>

                                    }
                                    <div className="play_block">
                                        <img src={play_compte} alt=""/>
                                        <p>{this.state.trad.pDescriptionPlaces11}</p>
                                    </div>
                                </div>
                                <div className="block_info">
                                    <div className="line_1">
                                        <div className="left">
                                            <h2>{this.state.name}</h2>
                                        </div>
                                        <div className="right otherpage">
                                            <a href="#">
                                                {
                                                    this.state.suivie ?
                                                        <img
                                                            onClick={(e) => this.handleSuivre(e, "suivie")}
                                                            src={this.state.lang === "fr" ? suivie : followed}
                                                            className="connectWalletLogo"
                                                            alt="connectWalletLogo"
                                                        />
                                                        :
                                                        <img
                                                            onClick={(e) => this.handleSuivre(e, "suivre")}
                                                            src={this.state.lang === "fr" ? suivre : suivreen}
                                                            className="connectWalletLogo"
                                                            alt="connectWalletLogo"
                                                        />
                                                }
                                            </a>
                                            <Link to="#">
                                                <img onClick={() => this.handleSendMessage()} src={this.state.lang === "fr" ? message : messageen} alt=""/>
                                            </Link>
                                            <div className="dotMenu">
                                                <img src={dot} onClick={this.handleOpenMenuDotUser} alt=""/>
                                                <ul id="dotMenuUser" className="dotMenu">
                                                    <li onClick={() => this.handleSignalement('user')}>{this.state.trad.pDescriptionPlaces13}</li>
                                                    <li onClick={() => this.handleBloquer()}>{this.state.trad.pDescriptionPlaces14}</li>
                                                    {
                                                        this.state.creator_verif &&
                                                        <li onClick={() => this.handleEpingle()}>{this.state.trad.pDescriptionPlaces9}
                                                        </li>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line_2">
                                        <div className="social_wonder">
                                            <p onClick={() => this.handleOpenList('follow')}>{this.state.followers}<br/><span>{this.state.trad.creatorFollowers}</span>
                                            </p>
                                            <p onClick={() => this.handleOpenList('suivis')}>{this.state.suivis}<br/><span>{this.state.trad.creatorFollow}</span>
                                            </p>
                                            <p onClick={() => this.handleOpenList('place')}>{this.state.places}<br/><span>{this.state.trad.headerNavSub7}</span>
                                            </p>
                                            <p onClick={() => this.handleOpenList('reposts')}>{this.state.reposts}<br/><span>{this.state.trad.creatorRepost}</span>
                                            </p>
                                            <p onClick={() => this.handleOpenList('instant')}>{this.state.instants}<br/><span>{this.state.trad.creatorMoments}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="line_3">
                                        {
                                            this.state.creator_verif ?
                                                <h2>{this.state.trad.creator}</h2>
                                                :
                                                <h2>{this.state.trad.pDescriptionUtilisateur7}</h2>
                                        }
                                        <p>{this.state.biographie}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="block_content_page">
                            <nav>
                                <ul>
                                    <li className={this.state.type === "recap" ? "active" : "no_active"}
                                        onClick={() => this.handleOpenBoard('recap')}><img src={repac} alt=""/>
                                        <p>{this.state.trad.creatorText1}</p></li>
                                    <li className={this.state.type === "video" ? "active" : "no_active"}
                                        onClick={() => this.handleOpenBoard('video')}><img src={video} alt=""/>
                                        <p>{this.state.trad.creatorText2}</p>
                                    </li>
                                    <li className={this.state.type === "reposts" ? "active" : "no_active"}
                                        onClick={() => this.handleOpenBoard('reposts')}><img src={reposts} alt=""/>
                                        <p>{this.state.trad.creatorRepost}</p></li>
                                    <li className={this.state.type === "playlist" ? "active" : "no_active"}
                                        onClick={() => this.handleOpenBoard('playlist')}><img src={playlist} alt=""/>
                                        <p>{this.state.trad.creatorText3}</p></li>
                                    <li className={this.state.type === "instant" ? "active" : "no_active"}
                                        onClick={() => this.handleOpenBoard('instant')}><img src={instant} alt=""/>
                                        <p>{this.state.trad.creatorMoments}</p></li>
                                </ul>
                            </nav>
                        </div>
                        <div className={`board ${this.state.type}`}>
                            {
                                this.state.type === 'recap' &&
                                <>
                                    {
                                        this.state.epingles[0] &&
                                        <EpingleUser callBackEpingle={this.callBackEpingle}
                                                     epingles={this.state.epingles}
                                                     user={this.state.user}/>
                                    }
                                    {
                                        this.state.likevideo[0] &&
                                        <LastLike id={this.state.id} videos={this.state.likevideo}/>
                                    }
                                    {
                                        this.state.playlists[0] &&
                                        <RecapPlaylist callBackPlaylist={this.callBackPlaylist}
                                                       playlists={this.state.playlists}/>
                                    }
                                    {
                                        this.state.shares[0] &&
                                        <RepostsLine shares={this.state.shares}/>
                                    }
                                    {
                                        this.state.lastPlaces[0] &&
                                        <LastPlaces lastPlaces={this.state.lastPlaces}/>
                                    }
                                    {
                                        this.state.lastCreators[0] &&
                                        <Creators creators={this.state.lastCreators}/>
                                    }
                                </>
                            }
                            {
                                this.state.type === "video" &&
                                <div className="list_video">
                                    {
                                        this.state.videos != null &&
                                        this.state.videos.map((value, index) => (
                                            <a href={`/video/${value.token}`}>
                                                <div className="block_video" key={index}>
                                                    <div className="img" onClick={() => this.handleEditVideo(index)}>
                                                        {
                                                            value.miniature !== null ?
                                                                <img
                                                                    src={import.meta.env.VITE_BACKEND_URL_IMG + value.miniature}
                                                                    alt=""/>
                                                                :
                                                                <img src={vignette} alt=""/>
                                                        }
                                                        <p className="time">{value.time}</p>
                                                    </div>
                                                    <div className="info_video">
                                                        <p>{value.title}</p>
                                                        <p>{this.state.artisteName}</p>
                                                        <p className="view"><img src={eye} alt=""/> 200</p>
                                                    </div>
                                                </div>
                                            </a>
                                        ))
                                    }
                                </div>
                            }
                            {
                                this.state.type == "reposts" &&
                                <Reposts user={this.state.user} id={this.state.id}/>
                            }
                            {
                                this.state.type == "playlist" &&
                                <PlaylistOther user={this.state.user} id={this.state.id}/>
                            }
                            {
                                this.state.type == "instant" &&
                                <p>Bientôt disponible</p>
                            }
                        </div>
                    </>
                }
                {
                    this.state.openModalBug &&
                    <SmallModal title={this.state.trad.pDescriptionUtilisateur3} label={this.state.trad.pDescriptionUtilisateur9} handleCloseModal={this.handleCloseModal}
                                callbackSend={this.callbackSend}/>
                }
                {
                    this.state.openModalContenu &&
                    <SmallModal title={this.state.trad.pDescriptionUtilisateur10} label={this.state.trad.pDescriptionUtilisateur11}
                                handleCloseModal={this.handleCloseModal} callbackSend={this.callbackSend}/>
                }
                {
                    this.state.modalListFollow &&
                    <ModalList
                        handleCloseModal={this.handleCloseModal}
                        followers={this.state.followers}
                        listFollow={this.state.listFollow}
                        listFullPlaces={this.state.listFullPlaces}
                        places={this.state.places}
                        listSuivi={this.state.listSuivi}
                        suivis={this.state.suivis}
                        modalType={this.state.modalType}
                    />
                }
            </>
        );
    }
}