import React, { useEffect, useRef, useState, ChangeEvent } from "react";
import { Cropper, CropperRef } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";
import "react-advanced-cropper/dist/themes/corners.css";
import crossIcon from "../../../assets/logos/crossIcon.svg";
import "./styles.scss";

const CropperAdv = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const cropperRef = useRef<CropperRef>(null);

  const [image, setImage] = useState<string>();

  const flip = (horizontal: boolean, vertical: boolean) => {
    if (cropperRef.current) {
      cropperRef.current.flipImage(horizontal, vertical);
    }
  };

  const rotate = (angle: number) => {
    if (cropperRef.current) {
      cropperRef.current.rotateImage(angle);
    }
  };

  const onUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const onCrop = () => {
    const cropper = cropperRef.current;
    if (cropper) {
      let canvas = null;
      if(props.index == 1){
        canvas = cropper.getCanvas({
          // Resize the result here !
          minHeight: 0,
          minWidth: 0,
          maxHeight: 250,
          maxWidth: 250,
        });
      }else{

        canvas = cropper.getCanvas({
          // Resize the result here !
          minHeight: 0,
          minWidth: 0,
          maxHeight: 1080,
          maxWidth: 1920,
        });
      }
      let elImg = document.querySelector(`#imgPreview${props.index}`);
      let elInput = document.querySelector(`#imageBase${props.index}`);
      if (canvas) {
        if (elImg) {
          if (elInput) {
            elImg.src = canvas.toDataURL();
            elInput.value = canvas.toDataURL();
            props.callBackCropper(canvas.toDataURL(), props.index);
            //props.closeModalCropp();
          }
        }
      }
    }
  };

  const onLoadImage = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
    }
    event.target.value = "";
  };

  useEffect(() => {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    return () => {
      if (image) {
        URL.revokeObjectURL(image);
      }
    };
  }, [image]);

  return (
    <div className="example">
      <div className="example__cropper-wrapper">
        <Cropper
          ref={cropperRef}
          className="example__cropper"
          backgroundClassName="example__cropper-background"
          src={image}
          aspectRatio={props.ratio}
        />
      </div>

      <div
        className={
          image == undefined
            ? "example__buttons-wrapper_start"
            : "example__buttons-wrapper"
        }
      >
        <button
          className={
            image == undefined ? "example__button_first" : "example__button"
          }
          onClick={onUpload}
        >
          <div className="chooseImg">
            <input
              ref={inputRef}
              type="file"
              accept="image/*"
              onChange={onLoadImage}
            />
            {image == undefined && <p>Choisir une image . . .</p>}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#fff"
              width="18"
              height="18"
              viewBox="0 0 24 24"
            >
              <path d="M10 18v-10h-2.51l4.51-5.01 4.51 5.01h-2.51v10h-4zm-2 2h8v-10h5l-9-10-9 10h5v10zm11-1v3h-14v-3h-2v5h18v-5h-2z" />
            </svg>
          </div>
        </button>

        {image !== undefined && (
          <>
            <button
              className="example__button"
              onClick={() => flip(true, false)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                <path
                  fill="#FFF"
                  d="M8 15.9c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1s1 .4 1 1v13.9c0 .6-.4 1-1 1zm4-2.4h-.5c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h.5c.4 0 .8.3.8.8s-.4.8-.8.8zm2.7 0h-.5c-.4 0-.8-.3-.8-.8 0-.3.2-.6.5-.7.1-.3.4-.5.7-.5.4 0 .8.3.8.8v.5c.1.4-.3.7-.7.7zm0-2.8c-.4 0-.8-.3-.8-.8v-.8c0-.4.3-.8.8-.8s.8.3.8.8v.8c0 .4-.4.8-.8.8zm0-3.3c-.4 0-.8-.3-.8-.8v-.7c0-.4.3-.8.8-.8s.8.3.8.8v.8c0 .4-.4.7-.8.7zm0-3.3c-.3 0-.6-.2-.7-.5-.3-.1-.5-.4-.5-.7 0-.4.3-.8.8-.8h.5c.4 0 .8.3.8.8v.5c-.1.4-.5.7-.9.7zM12 3.6h-.5c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h.5c.4 0 .8.3.8.8s-.4.8-.8.8zM4.5 13.5H1.3c-.4 0-.8-.3-.8-.8V2.9c0-.4.3-.8.8-.8h3.2v1.5H2V12h2.5v1.5z"
                ></path>
              </svg>
            </button>
            <button
              className="example__button"
              onClick={() => flip(false, true)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                <path
                  fill="#FFF"
                  d="M15 9H1c-.5 0-1-.5-1-1s.4-1 1-1h14c.6 0 1 .4 1 1s-.5 1-1 1zM3.1 12.7c-.4 0-.8-.3-.8-.8v-.5c0-.4.3-.8.8-.8s.8.3.8.8v.6c0 .4-.3.7-.8.7zM3.6 15.4h-.5c-.4 0-.8-.3-.8-.8v-.5c0-.4.3-.8.8-.8.3 0 .6.2.7.5.3.1.5.4.5.7.1.6-.2.9-.7.9zM10.1 15.4h-.8c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h.8c.4 0 .8.3.8.8s-.4.8-.8.8zm-3.2 0H6c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h.8c.4 0 .8.3.8.8s-.3.8-.7.8zM13 15.4h-.5c-.4 0-.8-.3-.8-.8 0-.3.2-.6.5-.7.1-.3.4-.5.7-.5.4 0 .8.3.8.8v.5c.1.4-.2.7-.7.7zM13 12.7c-.4 0-.8-.3-.8-.8v-.5c0-.4.3-.8.8-.8s.8.3.8.8v.6c0 .4-.3.7-.8.7z"
                ></path>
                <g>
                  <path
                    fill="#FFF"
                    d="M13.8 4.5h-1.5V2H3.9v2.5H2.4V1.3c0-.4.3-.8.8-.8H13c.4 0 .8.3.8.8v3.2z"
                  ></path>
                </g>
              </svg>
            </button>
            <button className="example__button" onClick={() => rotate(90)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                <path
                  fill="#FFF"
                  d="M12.5 11.8c.2.2.2.6 0 .9-1.1 1.1-2.8 1.8-4.5 1.8-3.7 0-6.5-2.8-6.5-6.5s3-6.5 6.5-6.5c1.7 0 3.3.7 4.5 1.8l1-1c.4-.4 1-.1 1 .4v3.8c0 .3-.3.6-.6.6h-3.7c-.5 0-.8-.7-.4-1L11 4.9c-.9-.9-1.9-1.3-3-1.3-3 0-5.4 3.1-4 6.2.7 1.6 2.3 2.6 4 2.6 1.2 0 2.2-.4 3-1.3.2-.2.6-.2.8 0l.7.7z"
                ></path>
              </svg>
            </button>
            <button className="example__button" onClick={() => rotate(-90)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                <path
                  fill="#FFF"
                  d="M3.5 11.8c-.2.2-.2.6 0 .9 1.1 1.1 2.8 1.8 4.5 1.8 3.7 0 6.5-2.8 6.5-6.5s-3-6.5-6.5-6.5c-1.7 0-3.3.7-4.5 1.8l-1-1c-.4-.4-1-.1-1 .4v3.8c0 .3.3.6.6.6h3.7c.5 0 .8-.7.4-1L5 4.9c.9-.9 1.9-1.3 3-1.3 3 0 5.4 3.1 4 6.2-.7 1.6-2.3 2.6-4 2.6-1.2 0-2.2-.4-3-1.3-.2-.2-.6-.2-.8 0l-.7.7z"
                ></path>
              </svg>
            </button>
            <button className="example__button" onClick={onCrop}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                <path
                  fill="#00ff00"
                  d="M.8 5.6c0-.5.3-1.2.6-1.6l2.7-2.7C4.4 1 5.2.7 5.7.7h8.7c.5 0 .9.5.9.9v12.6c0 .5-.5.9-.9.9H1.6c-.5 0-.9-.5-.9-.9V5.6h.1zm2.4 8.5v-3.9c0-.5.5-.9.9-.9h7.8c.5 0 .9.5.9.9v3.9H14V1.9h-1.2v3.9c0 .5-.5.9-.9.9H6.4c-.4 0-.7-.3-.7-.9V1.9c-.4 0-.7.1-.8.3L2.2 4.9c-.1.1-.3.4-.3.7V14h1.3zm8.4 0v-3.7H4.4V14h7.2v.1zM6.8 5.3c0 .2.2.3.3.3H9c.2 0 .3-.2.3-.3V2.2c0-.2-.2-.3-.3-.3H7.1c-.2 0-.3.1-.3.3v3.1z"
                ></path>
              </svg>
            </button>
          </>
        )}
        <button className="example__button" onClick={props.closeModalCropp}>
          <img
            className="crossIcon"
            src={crossIcon}
            alt="crossIcon"
            onClick={props.closeModalCropp}
            width="16"
            height="16"
          />
        </button>
      </div>
    </div>
  );
};
export default CropperAdv;
