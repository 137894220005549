import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import NavigateBackButton from "../../modules/NavigateBackButton.jsx";
import continuerButton from "../../../../assets/logos/changeValidButton.svg";
import continuerButtonen from "../../../../assets/logos/en/changeValidButtonen.svg";
import people from "../../../../assets/logos/people.svg";
import playlistImg from "../../../../assets/images/playlist_sample.png";
import VideoUpload from "../../modules/VideoUpload.jsx";
import ModalPlayList from "../../modules/ModalPlayList.jsx";
import { bannerHeight } from "../../modules/Helper.jsx";
import MenuUpload from "../../modules/MenuUpload.jsx";

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';

export default class UploadVideo_part2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marginTopValue: "21rem", // Valeur initiale de margin-top de la div "left" pour que l'element image soit respnsive
      sendLauch: false,
      user: null,
      video: null,

      upload_videoTitle: "",
      upload_artistWord: "",
      upload_otherInfos: "",

      // Options Lists
      upload_addPlaylistOptions: "",
      upload_interdictOptions: "",
      upload_langOptions: "Fr",
      upload_commercialCom: false,
      upload_productPlacement: false,
      upload_parrainnage: false,

      // MODAL PLAYLIST
      openModalPlaylist: false,
      playLists: [],
      edit: false,

      lang:'en',
      trad:en,
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem("user"))) {
      this.setState({
        user: JSON.parse(localStorage.getItem("user")),
      }, () => {
        let lang = this.state.user.user.language;
        if(lang === "fr"){
          this.setState({lang:"fr",trad:fr});
        }
      });
    }


    document.title = "Upload Video - Description | Wonderplace";
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    bannerHeight();
    if (localStorage.getItem("storageType")) {
      let user = localStorage.getItem("user");
      if (user !== null) {
        this.setState(
          {
            user: JSON.parse(user),
          },
          () => {
            let video = localStorage.getItem("currentVideo");
            this.setState(
              {
                video: JSON.parse(video),
              },
              () => {
                if (localStorage.getItem("editvideo")) {
            console.log(this.state.video);
                  this.setState({
                    upload_addPlaylistOptions:
                      this.state.video.infos.upload_addPlaylistOptions,
                    upload_artistWord: this.state.video.infos.upload_artistWord,
                    upload_commercialCom:
                      this.state.video.infos.upload_commercialCom,
                    upload_interdictOptions:
                      this.state.video.infos.upload_interdictOptions,
                    upload_langOptions:
                      this.state.video.infos.upload_langOptions,
                    upload_otherInfos: this.state.video.infos.upload_otherInfos,
                    upload_parrainnage:
                      this.state.video.infos.upload_parrainnage,
                    upload_productPlacement:
                      this.state.video.infos.upload_productPlacement,
                    upload_videoTitle: this.state.video.title,
                    edit: true,
                  });
                }
              }
            );
            const url = import.meta.env.VITE_BACKEND_URL + "/playlist/get";
            axios
              .get(url, {
                headers: {
                  Authorization: "Bearer " + this.state.user.token,
                },
              })
              .then((res) => {
                this.setState({ playLists: res.data });
              })
              .catch((err) => {
                console.log(err);
              });
          }
        );
      } else {
        location.assign("/");
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  // Adapte "MarginTop" de la div "left"  afin de descendre en mobile
  handleResize = () => {
    const resolution = window.innerWidth;

    // Vérifier si la résolution est inférieure ou égale à 1280 pixels
    if (resolution < 1280) {
      // Calculer la nouvelle valeur "top" en fonction de la résolution
      const newMarginTopValue = 21 + resolution / 100; // Par exemple

      // Mettre à jour la valeur "MarginTop" dans le state
      this.setState({
        marginTopValue: newMarginTopValue + "rem",
      });
    } else {
      this.setState({
        marginTopValue: 0 + "rem",
      });
    }
  };

  handleChange_ArtistWord = (value) => {
      this.setState({
        upload_artistWord: value,
      });
  };

  handleChange_OtherInfos = (value) => {
      this.setState({
        upload_otherInfos: value,
      });
  };

  ////// Options lists Categories
  handleOptionChange_addPlaylistOptions = (event) => {
    if (event.target.value === "Ajouter une playlist") {
      this.setState({ openModalPlaylist: true });
    } else {
      this.setState({ upload_addPlaylistOptions: event.target.value });
    }
  };
  handlePlayListRefresh = (data) => {
    this.setState({ playLists: data, openModalPlaylist: false });
  };
  handleCloseModal = () => {
    this.setState({
      openModalPlaylist: false,
    });
  };
  addPlaylistOptions = [
    { value: "", text: "Choisissez une playlist svp" },
    { value: "0", text: "Ajouter une playlist" },
  ];

  handleOptionChange_interdictOptions = (event) => {
    this.setState({ upload_interdictOptions: event.target.value });
  };
  interdictOptions = [
    { value: "", text: "Choisissez une interdiction svp" },
    { value: "a", text: "Pas d’interdiction" },
    { value: "b", text: "Interdiction -18" },
    { value: "c", text: "Interdiction -16" },
    { value: "d", text: "Interdiction -13" },
    { value: "e", text: "Explicite" },
  ];

  handleOptionChange_langOptions = (event) => {
    this.setState({ upload_langOptions: event.target.value });
  };
  langOptions = [
    // { value: "", text: "-- Choisissez une langue svp --" },
    { value: "fr", text: "Français (Par défaut)" },
    { value: "en", text: "Anglais" },
  ];

  handleUploadContinue = async () => {
    let {
      upload_videoTitle,
      upload_artistWord,
      upload_otherInfos,
      upload_addPlaylistOptions,
      upload_interdictOptions,
      upload_langOptions,
      upload_commercialCom,
      upload_productPlacement,
      upload_parrainnage,
      edit,
    } = this.state;
    const url = import.meta.env.VITE_BACKEND_URL + "/upload/savedescription";
    axios
      .post(
        url,
        {
          id: this.state.video.id,
          upload_videoTitle,
          upload_artistWord,
          upload_otherInfos,
          upload_addPlaylistOptions,
          upload_interdictOptions,
          upload_langOptions,
          upload_commercialCom,
          upload_productPlacement,
          upload_parrainnage,
          edit,
        },
        {
          headers: {
            Authorization: "Bearer " + this.state.user.token,
          },
        }
      )
      .then((res) => {
        if (res.data) {
          if (localStorage.getItem("editvideo")) {
            localStorage.setItem("currentVideo", JSON.stringify(res.data));
            location.assign("/gestion-de-ma-page/upload-video-part3");
          } else {
            localStorage.setItem(
              "message",
              "success:Merci, votre vidéo a bien été enregistrée"
            );
            location.assign("/gestion-de-ma-page");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <div className="uploadVideoGlobal_Part2">
        <div className="uploadHeader">
          <h1>{this.state.trad.uploadHeader}</h1>
          {this.state.edit && <MenuUpload active={2} />}
        </div>

        <div className="uploadContainer">
          <section
            className="left"
            style={{ marginTop: this.state.marginTopValue }}
          >
            <div className="upload_Input">
              <label htmlFor="upload_videoTitle">{this.state.trad.uploadVideoTitle}</label>
              <input
                type="text"
                id="upload_videoTitle"
                name="upload_videoTitle"
                value={this.state.upload_videoTitle}
                onChange={(e) =>
                  this.setState({ upload_videoTitle: e.target.value })
                }
              />
            </div>

            <div className="upload_Area">
              <label htmlFor="upload_artistWordArea">{this.state.trad.uploadArtistWordArea}</label>
              <textarea
                name="upload_artistWordArea"
                id="upload_artistWordArea"
                onChange={(e) => this.handleChange_ArtistWord(e.target.value)}
                value={this.state.upload_artistWord}
              />
            </div>

            <div className="upload_Area">
              <label htmlFor="upload_otherInfosArea">{this.state.trad.uploadOtherInfosArea}</label>
              <textarea
                name="upload_otherInfosArea"
                id="upload_otherInfosArea"
                onChange={(e) => this.handleChange_OtherInfos(e.target.value)}
                value={this.state.upload_otherInfos}
              />
            </div>
            {/*
            <div className="upload_Select">
              <label htmlFor="upload_addPlaylist">Ajout à une playlist</label>
              <img src={people} alt="" className="icon_playlist_select"/>
              <select
                id="upload_addPlaylist"
                name="upload_addPlaylist"
                value={this.state.upload_addPlaylistOptions}
                onChange={this.handleOptionChange_addPlaylistOptions}
              >
                {this.addPlaylistOptions.map((option) => (
                  <option
                    id="upload_addPlaylist_option"
                    name="upload_addPlaylist_option"
                    key={option.value}
                    value={option.text}
                    selected={this.state.upload_addPlaylistOptions === option.text}
                  >
                    {option.text}
                  </option>
                ))}
                {
                  this.state.playLists.map((value,index) => (
                    <option
                        id="upload_addPlaylist_option"
                        name="upload_addPlaylist_option"
                        key={index}
                        value={value.name}
                        selected={value.name === this.state.upload_playlist}
                    >
                      {value.name}
                    </option>
                  ))
                }
              </select>
            </div>
*/}
            <div className="upload_Select">
              <label htmlFor="upload_interdict">{this.state.trad.uploadInterdict}</label>
              <select
                id="upload_interdict"
                name="upload_interdict"
                value={this.state.upload_interdictOptions}
                onChange={this.handleOptionChange_interdictOptions}
              >
                {this.interdictOptions.map((option) => (
                  <option
                    id="upload_interdict_option"
                    name="upload_interdict_option"
                    key={option.value}
                    value={option.text}
                  >
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
          </section>

          <section className="right">
            <div className="upload_VignetteSection">
              <div className="upload_VignetteText">
                {this.state.video && (
                  <div className="vignette">
                    {this.state.edit ? (
                      <VideoUpload
                        indexVideo={0}
                        upload={false}
                        id={this.state.video.id}
                        video={
                          import.meta.env.VITE_BACKEND_URL_IMG +
                          this.state.video.path_nocompres
                        }
                      />
                    ) : (
                      <VideoUpload
                        indexVideo={0}
                        upload={false}
                        id={this.state.video.id}
                        video={this.state.video}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="languageSelect">
              <label htmlFor="languageSelect_select">
                {this.state.trad.languageSelect2}
              </label>
              <select
                id="languageSelect_select"
                name="languageSelect_select"
                value={this.state.upload_langOptions || ""}
                onChange={this.handleOptionChange_langOptions}
              >
                {this.langOptions.map((option) => (
                  <option
                    id="languageSelect_option"
                    name="languageSelect_option"
                    key={option.value}
                    value={option.value || ""}
                  >
                    {option.text}
                  </option>
                ))}
              </select>
            </div>

            <div className="mentions">
              <p>{this.state.trad.mentions}</p>
              <div className="form_control_Container">
                <div className="form_control">
                  <input
                    type="checkbox"
                    id="upload_commercialCom"
                    name="upload_commercialCom"
                    checked={this.state.upload_commercialCom ? "checked" : ""}
                    onChange={(e) =>
                      this.setState({
                        upload_commercialCom: e.target.checked,
                      })
                    }
                  />
                  <label htmlFor="upload_commercialCom">{this.state.trad.uploadCommercialCom}</label>
                </div>

                <div className="form_control">
                  <input
                    type="checkbox"
                    id="upload_productPlacement"
                    name="upload_productPlacement"
                    checked={
                      this.state.upload_productPlacement ? "checked" : ""
                    }
                    onChange={(e) =>
                      this.setState({
                        upload_productPlacement: e.target.checked,
                      })
                    }
                  />
                  <label htmlFor="upload_productPlacement">{this.state.trad.uploadProductPlacement}</label>
                </div>

                <div className="form_control">
                  <input
                    type="checkbox"
                    id="upload_parrainnage"
                    name="upload_parrainnage"
                    checked={this.state.upload_parrainnage ? "checked" : ""}
                    onChange={(e) =>
                      this.setState({ upload_parrainnage: e.target.checked })
                    }
                  />
                  <label htmlFor="upload_parrainnage">{this.state.trad.uploadParrainnage}</label>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="navButtons">
          <NavigateBackButton />

          <div className="form_submit_container">
            {this.state.sendLauch ? (
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              <div className="form_submit">
                <img
                    src={this.state.lang === "fr" ? continuerButton : continuerButtonen}
                  alt="continuer"
                  className="continuer"
                  onClick={this.handleUploadContinue}
                />
              </div>
            )}
          </div>
        </div>
        {this.state.openModalPlaylist && (
          <ModalPlayList
            handleCloseModal={this.handleCloseModal}
            handlePlayListRefresh={this.handlePlayListRefresh}
          />
        )}
      </div>
    );
  }
}
