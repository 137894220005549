import { useState, useEffect, useRef } from "react";
// import PropTypes from "prop-types";
import videojs from "video.js";
import "video.js/dist/video-js.css";

const VideoJSFull = (props) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const { options, onReady } = props;
    const [indexVideo, setIndexVideo] = useState(props.indexVideo);
    const [id, setId] = useState(props.id);


    const handleNextVideo = async () => {
        const player = playerRef.current;
        player.pause();

        let newId =
            videoRef.current.parentElement.parentElement.parentElement.id.split("_");
        newId = parseInt(newId[1]) + 1;
        newId = `video_${newId}`;

        const nextVideo = document.getElementById(newId);

        // Faites défiler la page jusqu'à l'élément vidéo suivant
        nextVideo.scrollIntoView({ behavior: "smooth" });
        props.handleCallback(indexVideo);

        // videojs.log(nextVideo);
    };

    const handleFullScreen = () => {
        const player = playerRef.current;
        let time = player.cache_.currentTime;
        player.pause();
        const isFullscreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
        if (isFullscreen) {
            // Stopper le mode plein écran
            try {
                if (document.fullscreenElement && document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozFullScreenElement && document.mozCancelFullScreen) { // Firefox
                    document.mozCancelFullScreen();
                } else if (document.webkitFullscreenElement && document.webkitExitFullscreen) { // Chrome, Safari et Opera
                    document.webkitExitFullscreen();
                } else if (document.msFullscreenElement && document.msExitFullscreen) { // IE/Edge
                    document.msExitFullscreen();
                }
                // Revenir à l'orientation par défaut
                const isDesktop = window.innerWidth <= 1280;
                if (isDesktop) {
                    if (screen.orientation && screen.orientation.unlock) {
                        screen.orientation.unlock();
                    }
                }
                props.handleCallBackFullScreen(false, indexVideo, time)
            } catch (error) {
                console.log("Failed to exit fullscreen mode", error);
            }
        }
    }

    useEffect(() => {
        // Make sure Video.js player is only initialized once
        if (!playerRef.current) {
            // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
            const videoElement = document.createElement("video-js");

            videoElement.classList.add("vjs-big-play-centered");
            videoRef.current.appendChild(videoElement);

            let isTimeUpdated = false;
            const player = (playerRef.current = videojs(videoElement, options, () => {
                //videojs.log("player is ready");
                onReady && onReady(player);
            }));
            player.on('canplay', function() {
                if (!isTimeUpdated) {
                        this.currentTime(options.currentTime);
                    isTimeUpdated = true;
                }
            });

            player.controlBar.addChild(
                "button",
                {
                    text: "FullScreen",
                    el: videojs.dom.createEl("FullButton", {
                        className: "vjs-lowscreen-control",
                        title: "FullScreen",
                        role: "button",
                    }),
                    clickHandler: handleFullScreen,
                },
                // Add the RestartButton to the control bar and specify the display order
                player.controlBar.children().length - 0
            );

            // Add a restart button to the control bar
            player.controlBar.addChild(
                "button",
                {
                    text: "Restart",
                    el: videojs.dom.createEl("RestartButton", {
                        className: "vjs-icon-restart",
                        title: "Restart",
                        role: "button",
                    }),
                    clickHandler: function () {
                        player.currentTime(0);
                        player.play().catch(function(erreur){

                        });
                    },
                },

                // Add the RestartButton to the control bar and specify the display order
                player.controlBar.children().length - 0
            );

            // Add a NextVideo button to the control bar
            //player.controlBar.addChild(
            //    "button",
            //    {
            //        text: "NextVideo",
            //        el: videojs.dom.createEl("NextVideoButton", {
            //            className: "vjs-icon-NextVideo",
            //            title: "NextVideo",
            //            role: "button",
            //        }),
            //        clickHandler: handleNextVideo,
            //    },
//
            //    // Add the NextVideoButton to the control bar and specify the display order
            //    player.controlBar.children().length - 0
            //);

            // You could update an existing player in the `else` block here
            // on prop change, for example:
        } else {
            const player = playerRef.current;

            player.autoplay(options.autoplay);
            player.src(options.sources);
        }
    }, [options, videoRef]);

    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;

            }
        };
    }, [playerRef]);

    return (
        <div data-vjs-player className="video-js-player">
            <div ref={videoRef} className="video-js" />
        </div>
    );
};

// VideoJS.propTypes = {
//   options: PropTypes.object.isRequired,
//   onReady: PropTypes.func.isRequired,
// };

export default VideoJSFull;
