import React, {Component} from 'react';
import axios from "axios";

import en from '../../../../locales/en/translation.json';
import fr from '../../../../locales/fr/translation.json';

export default class Explorer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            header:null,
            lang:'en',
            trad:en,
        }
    }

    componentDidMount() {
        let url = import.meta.env.VITE_BACKEND_URL +  "/explorer/get";
        axios.get(url).then(res => {
            this.setState({
                header: Object.values(res.data.header),
            })
        }).catch(err => {
            console.log(err);
        })
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }
    }


    render(){
        return (
            <div className="content">
                <div className="header_explorer">
                    {
                        this.state.header !== null &&
                        this.state.header.map((value,index) => (
                            <div key={index} className="block">
                                {
                                    index == 0 &&
                                    <a href={`/page-de-profil/${value.id}`}>
                                        <div className="img">
                                            <img src={import.meta.env.VITE_BACKEND_URL_IMG + value.personal.avatar} alt=""/>
                                        </div>
                                        <div className="titre">
                                            <p>{value.nom_artiste}</p>
                                        </div>
                                        <div className="soustitle">
                                            <p>{this.state.trad.pDescriptionPlaces}</p>
                                        </div>
                                    </a>

                                }
                                {
                                    index == 1 &&
                                    <>
                                        <div className="img">
                                            <img src="" alt=""/>
                                        </div>
                                        <div className="titre">
                                        </div>
                                        <div className="soustitle">
                                            <p>{this.state.trad.pDescriptionPlaces1}</p>
                                        </div>
                                    </>
                                }
                                {
                                    index == 2 &&
                                    <>
                                        <div className="img">
                                            <img src="" alt=""/>
                                        </div>
                                        <div className="titre">
                                        </div>
                                        <div className="soustitle">
                                            <p>{this.state.trad.pDescriptionPlaces2}</p>
                                        </div>
                                    </>
                                }
                                {
                                    index == 3 &&
                                    <>
                                        <div className="img">
                                            <img src="" alt=""/>
                                        </div>
                                        <div className="titre">
                                            <p></p>
                                        </div>
                                        <div className="soustitle">
                                            <p>{this.state.trad.pDescriptionPlaces3}</p>
                                        </div>
                                    </>
                                }
                                {
                                    index == 4 &&
                                    <a href={`/video/${value.token}`}>
                                        <div className="img">
                                            <img src={import.meta.env.VITE_BACKEND_URL_IMG + value.miniature} alt=""/>
                                        </div>
                                        <div className="titre">
                                            <p>{value.title}</p>
                                        </div>
                                        <div className="soustitle">
                                            <p>{this.state.trad.pDescriptionPlaces4}</p>
                                        </div>
                                    </a>
                                }
                            </div>
                        ))
                    }
                </div>
                <div className="menu">

                </div>
                <div className="list">

                </div>
            </div>
        )
    }

}