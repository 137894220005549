import React, {useEffect, useRef, useState} from "react";
import videojs from "video.js";

import VideoJS from "../modules/VideoJS";
import axios from "axios";
import videoLikeIcon from "../../../assets/logos/videoLikeIcon.svg";
import videoLikeFullIcon from "../../../assets/logos/videoLikeFullIcon.svg";
import videoUnlikeIcon from "../../../assets/logos/coeur_vide.svg";
import videoUnlikeIconPlein from "../../../assets/logos/coeur_plein.svg";
import videoCommentIcon from "../../../assets/logos/videoCommentIcon.svg";
import videoBookmarkIcon from "../../../assets/logos/videoBookmarkIcon.svg";
import videoBookmarkIconFull from "../../../assets/logos/videoBookmarkIconFull.svg";
import videoFollowIcon from "../../../assets/logos/videoFollowIcon.svg";
import videoPlusIcon from "../../../assets/logos/videoPlusIcon.svg";

// genreIcons pour test en dur //
import genreSombreIcon from "../../../assets/samples/genreSombreIcon.svg";
import Connexion from "./Connexion";
import Commentaire from "./Commentaire";
import BookMarke from "./BookMarke.jsx";
import Follow from "./Follow.jsx";
//////////////////////////////////////////////////////////////////////////////////

const VideoPlayer = (props) => {
    const playerRef = useRef(null);
    const [video, setVideo] = useState(props.video);
    const [id, setId] = useState(props.id);
    const [indexVideo, setIndexVideo] = useState(props.indexVideo);
    const [refresh, setRefresh] = useState(false);
    const [currentTime, setCurrentTime] = useState(props.currentTime);
    const [duration, setDuration] = useState();
    const [user, setUser] = useState(props.user);
    const [openModal, setOpenModal] = useState(false);
    const [openModalCom, setOpenModalCom] = useState(false);
    const [openModalBook, setOpenModalBook] = useState(false);
    const [openModalFollow, setOpenModalFollow] = useState(false);
    const [message, setMessage] = useState(false);
    const [styleMessage, setStyleMessage] = useState(false);

    // Player Footer Variables //
    const artistPhoto = video.artistPhoto;
    const artistName = video.artistName;
    const artistTitle = video.artistTitle;
    const title = video.title;
    const artistDescription = video.artistDescription;
    const artistMoreDetails = video.artistMoreDetails;
    const userId = video.userId;

    // Player Footer States //
    const [hasLiked, setHasLiked] = useState(video.hasLiked);
    const [isBooked, setIsBooked] = useState(video.isBooked);
    const [hasDisLiked, setHasDisLiked] = useState(video.hasDisLiked);
    const [isLiked, setIsLiked] = useState(video.isLiked);
    const [isCommented, setIsCommented] = useState(video.isCommented);
    const [isBoorkmarked, setIsBoorkmarked] = useState(video.isBoorkmarked);
    const [isFollowed, setIsFollowed] = useState(video.isFollowed);
    const [genreIcon, setGenreIcon] = useState([video.genreIcon]);

    const videoJsOptions = {
        autoplay: video.isPlay,
        controls: true,
        preload: "auto",
        responsive: true,
        fluid: true,
        muted: true,
        aspectRatio: "16:9",
        currentTime: currentTime,
        controlBar: {
            remainingTimeDisplay: false,
            disablePictureInPicture: true,
            timeDivider: true,
            children: [
                "playToggle",
                "volumePanel",
                "progressControl",
                "currentTimeDisplay",
                "timeDivider",
                "durationDisplay",
                //"fullscreenToggle",
                // "restartButton",  //// RestartButton is custom, the order is done in VideoJS.jsx with the index
                //  "nextVideoButton",  //// NextVideoButton is custom, the order is done in VideoJS.jsx with the index
            ],
        },
        sources: [video],
        poster: video.poster,
    };

    // Player Fonctions //

    useEffect(() => {
        setVideo(props.video);
        videoJsOptions.sources = video;
        setRefresh(true);


        if (props.video.isPlay) {
            handlePlay();
        } else {
            handlePause();
        }
    }, [video, refresh, playerRef, props.video.isPlay, styleMessage, message]);

    const handlePlayerReady = (player) => {
        playerRef.current = player;
        player.on("loadedmetadata", () => {
            const duration = player.duration();
            setDuration(duration);
        });

        player.on("waiting", () => {
            //videojs.log("player is waiting");
        });
        player.on("dispose", () => {
            //videojs.log("player will dispose");
        });
    };
    const handlePlay = () => {
        if (playerRef.current) {
            playerRef.current.play().catch(function(erreur){

            });
        }
    };
    const handlePause = () => {
        if (playerRef.current) {
            // videojs.log(playerRef.current);
            playerRef.current.pause();
        }
    };
    const handleCallback = (id) => {
        let newId = parseInt(id) + 1;
        setIndexVideo(newId);
        props.handleCallBackPlay(newId);
        // setRefresh(true);
    };
    // Player FooterIcons //

    const handleLike = () => {
        if (user !== null) {
            let url = import.meta.env.VITE_BACKEND_URL + "/like/likevideo";
            axios.post(url, {
                id: video.id,
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + user.token
                }
            }).then(res => {
                setIsLiked(res.data.like);
                setHasLiked(res.data.hasLiked)
            }).catch(err => {
                console.log(err);
            })
        } else {
            setMessage('Vous devez être connecter pour liker une vidéo');
            setStyleMessage('warning');
            setOpenModal(true);
        }
    }
    const handleUnlike = () => {
        if (user !== null) {
            let url = import.meta.env.VITE_BACKEND_URL + "/like/dislikevideo";
            axios.post(url, {
                id: video.id,
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + user.token
                }
            }).then(res => {
                setIsLiked(res.data.like);
                setHasLiked(res.data.hasLiked)
                setHasDisLiked(res.data.hasDisLiked)
            }).catch(err => {
                console.log(err);
            })
        } else {
            setMessage('Vous devez être connecter pour disliker une vidéo');
            setStyleMessage('warning');
            setOpenModal(true);
        }
    };
    const handleComment = () => {
        if (user !== null) {
            setOpenModalCom(true);
        } else {
            setMessage('Vous devez être connecter pour voir les commentaires');
            setStyleMessage('warning');
            setOpenModal(true);
        }
    };

    const handleBookmark = () => {
        if (user !== null) {
            setOpenModalBook(true);
        } else {
            setMessage('Vous devez être connecter pour enregistrer un Bookmarke.');
            setStyleMessage('warning');
            setOpenModal(true);
        }
    };

    const handleFollow = () => {
        if (user !== null) {
            setOpenModalFollow(true);
        } else {
            setMessage('Vous devez être connecter pour enregistrer un Bookmarke.');
            setStyleMessage('warning');
            setOpenModal(true);
        }
    };
    const handlePlus = () => {
        setGenreIcon((prevIcons) => [
            ...prevIcons,
            <img
                src={genreSombreIcon}
                alt="Video Genre Sombre Icon"
                className="genreIcon"
                key={`genreIcon${prevIcons.length}`}
            />,
        ]);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
        setOpenModalCom(false);
        setOpenModalBook(false);
        setOpenModalFollow(false);
        setMessage("");
        setStyleMessage('');
        props.callBackFreshPage();
    }

    return (
        <>
            {
                openModal &&
                <Connexion handleCloseModal={handleCloseModal} message={message} styleMessage={styleMessage}/>
            }
            {
                openModalCom &&
                <Commentaire handleCloseModal={handleCloseModal} idvideo={video.id} user={user}/>
            }
            {
                openModalBook &&
                <BookMarke handleCloseModal={handleCloseModal} idvideo={video.id} user={user}/>
            }
            {
                openModalFollow &&
                <Follow handleCloseModal={handleCloseModal} video={video}  user={user}/>
            }
            <div className="videoPlayerGlobal small" id={id}>
                <div className="videoPlayerContainer">
                    <VideoJS
                        options={videoJsOptions}
                        handleCallback={handleCallback}
                        handleCallBackFullScreen={(props.handleCallBackFullScreen)}
                        indexVideo={indexVideo}
                        onReady={handlePlayerReady}
                        ref={playerRef}
                        indexID={indexVideo}
                        callbackIndexVideo={props.callbackIndexVideo}
                        duration={duration}
                        id={id}
                    />
                    <div className="barBuy">

                    </div>
                    <div className="videoFooter">
                        <div className="artistDetailsContainer">
                            <a href={`/page-de-profil/${userId}`}>
                            <img
                                className="artistPhoto"
                                src={artistPhoto}
                                alt="artistPhoto"
                            ></img>
                            </a>
                            <div className="artistInfosContainer">
                                <div className="artistInfos">
                                    <h3 className="artistName">{title} &nbsp;— &nbsp; {artistTitle} .&nbsp;.&nbsp;.</h3>
                                </div>
                                <p className="artistDescription">{artistDescription}</p>
                                {/*
                                <p className="artistMoreDetails">{artistMoreDetails}</p>
                                */}
                            </div>
                        </div>


                        <div className="videoIconsContainer">
                            <div className="videoIcons">
                                <div className="video_icon">
                                    <label>{isLiked}</label>
                                    <img
                                        src={hasLiked ? videoLikeFullIcon : videoLikeIcon}
                                        alt="Video Like Icon"
                                        className="videoLikeIcon"
                                        onClick={handleLike}
                                    />
                                </div>

                                <div className="video_icon">
                                    <img
                                        src={hasDisLiked ? videoUnlikeIconPlein : videoUnlikeIcon}
                                        alt="Video Unlike Icon"
                                        className="videoUnlikeIcon"
                                        onClick={handleUnlike}
                                    />
                                </div>

                                <div className="video_icon">
                                    <label>{isCommented}</label>
                                    <img
                                        src={videoCommentIcon}
                                        alt="Video Comment Icon"
                                        className="videoCommentIcon"
                                        onClick={handleComment}
                                    />
                                </div>

                                <div className="video_icon">
                                    <label>{isBoorkmarked}</label>
                                    <img
                                        src={isBooked ? videoBookmarkIconFull : videoBookmarkIcon}
                                        alt="Video Bookmark Icon"
                                        className="videoBookmarkIcon"
                                        onClick={handleBookmark}
                                    />
                                </div>


                                <div className="video_icon">
                                    <label>{isFollowed}</label>
                                    <img
                                        src={videoFollowIcon}
                                        alt="Video Follow Icon"
                                        className="videoFollowIcon"
                                        onClick={handleFollow}
                                    />
                                </div>

                            </div>
                            {/*
                            <div className="videoGenresContainer">
                                <div className="videoGenres">
                                    {genreIcon.map((icon, index) => (
                                        <li className="video_genre_icon" key={index}>
                                            {icon}
                                        </li>
                                    ))}

                                    <div className="video_genre_icon">
                                        <img
                                            src={videoPlusIcon}
                                            alt="Video Plus Icon"
                                            className="videoPlusIcon"
                                            onClick={handlePlus}
                                        />
                                    </div>
                                </div>
                            </div>
                            */}
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default VideoPlayer;
