import React from "react";
import CropperAdv from "../modules/CropperAdv";
import axios from "axios";
import moment from 'moment';

import continuerButton from "../../../assets/logos/continuerButton.svg";
import continuerButtonen from "../../../assets/logos/en/continuerButtonen.svg";
import inscriptionEndButton from "../../../assets/logos/inscriptionEndButton.svg";
import inscriptionEndButtonen from "../../../assets/logos/en/inscriptionEndButtonen.svg";
import loupe from "../../../assets/logos/loupe.svg";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiSwitch from "../modules/MuiSwitch.jsx";

import en from '../../../locales/en/translation.json';
import fr from '../../../locales/fr/translation.json';

export default class InscriptionNext extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            /////////////// Part 1 /////////////////
            part1: 1,
            part2: 0,
            part3: 0,
            cropperAvatar: false,
            cropperCover: false,
            avatar: "",
            cover: "",
            avatarInput: "",
            coverInput: "",
            userName: "@",
            biographie: "",
            cara: 0,
            jour: "",
            mois: "",
            annee: "",
            date: "",
            currentDate: new Date(),
            /////////////// Part 2 /////////////////
            searchInput: "",
            follow: [],
            /////////////// Part 3 /////////////////
            selectedOption: "",
            otherInput: "",
            geolocation: false,
            newsletter: true,
            sync: false,

            style: "warning",

            places: null,
            placesFull: null,
            myPlaces: [],
            creator:false,

            lang:'en',
            trad:en,
        };
    }

    componentDidMount() {
        if (JSON.parse(localStorage.getItem("user"))) {
            this.setState({
                user: JSON.parse(localStorage.getItem("user")),
            }, () => {
                let lang = this.state.user.user.language;
                if(lang === "fr"){
                    this.setState({lang:"fr",trad:fr});
                }
            });
        }

        document.title = "Inscription | Wonderplace";
        let id = location.search.split('=');
        this.setState({userId: id[1]});
        let url = import.meta.env.VITE_BACKEND_URL + "/places/places";
        let date = new Date();
        let formattedDate = date.toISOString().split('T')[0];
        this.setState({currentDate: formattedDate});
        axios.get(url).then(res => {
            this.setState({places: res.data, placesFull: res.data});
        }).catch(err => {
            console.log(err);
        })
    }

    handleInscription = (value) => {
        const {currentDate, date} = this.state;
        if (value == 1) {
            if (this.state.userName != "@") {
                if (date !== "") {
                    let tabDate = date.split('-');
                    let tabDateCurrent = currentDate.split('-');
                    let majeur = parseInt(tabDateCurrent[0]) - 13;
                    if(tabDate[0] <= majeur){
                        this.setState({
                            message: "",
                            style: "warning",
                        })
                        let url = import.meta.env.VITE_BACKEND_URL + "/utilisateur/checkUserName";
                        axios.post(url, {
                            name: this.state.userName,
                        }).then(res => {
                            if (res.data === true) {
                                this.setState({
                                    message: this.state.trad.messageUsername
                                })
                            } else {
                                this.setState({
                                    part1: 2,
                                    part2: 1,
                                    part3: 0,
                                });
                            }
                        }).catch(error => {
                            console.log(error);
                        })
                    }else{
                        this.setState({
                            message: this.state.trad.messageRegistration,
                            style: "warning",
                        })
                    }
                } else {
                    this.setState({
                        message: this.state.trad.messageRegistration1,
                        style: "warning",
                    })
                }
            } else {
                this.setState({
                    message: this.state.trad.messageRegistration2,
                    style: "warning",
                })

            }
        } else if (value == 2) {
            this.setState({
                part1: 2,
                part2: 2,
                part3: 1,
            });
        }
    };

    ///////////////////// Part 1 Functions /////////////////////

    handleChangeBio = (value) => {
        let cara = value.length;
        if (cara <= 100) {
            this.setState({
                cara,
                biographie: value,
            });
        } else {
            console.log("hello");
        }
    };

    handleChangeDate = (day, mouth, year) => {
        let newDay = moment(day).date();
        let newMouth = moment(mouth).month();
        let newYear = moment(year).year();
        this.setState({
            jour: newDay,
            mois: newMouth + 1,
            annee: newYear,
        })
    };

    closeModalCropp = () => {
        let elImg1 = document.querySelector(`#imageBase1`);
        let elImg2 = document.querySelector(`#imageBase2`);
        if (elImg1.value !== "") {
            document.querySelector(".plusAvatar").style.display = "none";
            this.setState({avatar: elImg1.value})
        }
        if (elImg2.value !== "") {
            document.querySelector(".plusCover").style.display = "none";
            this.setState({cover: elImg2.value})
        }

        this.setState({
            cropperAvatar: false,
            cropperCover: false,
        });
    };

    handleOpenAvatar = () => {
        this.setState({cropperAvatar: true});
    };

    handleOpenCover = () => {
        this.setState({cropperCover: true});
    };

    handleChangeCreator = (e) => {
        this.setState({creator: e.target.checked});
    }

    ///////////////////// Part 2 Functions /////////////////////

    searchResult = () => {
        let {searchInput} = this.state;
        console.log(searchInput);
    };

    handleFollow = (index) => {
        let {follow, myPlaces, places} = this.state;
        if (follow.indexOf(index) !== -1) {

            let newPlaces = myPlaces.filter(mp => mp.id !== places[index].id);
            let index2 = follow.indexOf(index);
            follow.splice(index2, 1);
            this.setState({follow: follow, myPlaces: newPlaces});
        } else {
            follow.push(index);
            myPlaces.push(places[index]);
            this.setState({follow, myPlaces});
        }
    };

    ///////////////////// Part 3 Functions /////////////////////

    otherResult = () => {
        let {otherInput} = this.state;
    };

    handleOptionChange = (event) => {
        this.setState({selectedOption: event.target.value});
    };

    options = [
        {value: "", text: this.state.trad.optionInscription},
        {value: "mouth", text: this.state.trad.optionInscription1},
        {value: "seek", text: this.state.trad.optionInscription2},
        {value: "events", text: this.state.trad.optionInscription3},
        {value: "reseaux", text: this.state.trad.optionInscription4},
        {value: "mails", text: this.state.trad.optionInscription5},
    ];

    handleSave = () => {
        let {
            avatar,
            cover,
            userName,
            jour,
            mois,
            annee,
            biographie,
            selectedOption,
            otherInput,
            newsletter,
            myPlaces,
            userId,
            creator,
        } = this.state;
        if (selectedOption !== "") {
            let platform = "";
            let test = userName.substring(0, 1);
            if (test !== "@") {
                userName = "@" + userName;
            }
            if (otherInput !== "" && selectedOption === "") {
                platform = otherInput;
            } else if (otherInput === "" && selectedOption !== "") {
                platform = selectedOption;
            } else {
                platform = selectedOption + " / " + otherInput;
            }
            let url = import.meta.env.VITE_BACKEND_URL + "/utilisateur/create/personnalisation";
            axios.post(url, {
                user_id: userId,
                avatar,
                cover,
                name: userName,
                biographie,
                birthday: jour + "-" + mois + "-" + annee,
                newsletter,
                platform,
                myPlaces,
                creator
            }).then(res => {
                if (res.data.id) {
                    localStorage.removeItem('inscription');
                    location.assign('/?login=true');
                }
            }).catch(error => {
                console.log(error);
            })
        } else {
            this.setState({
                message: this.state.trad.messageRegistration3,
                style: "warning",
            })
        }
    }
    callBackCropper = (base64Img, index) => {
        if (index === 1) {
            this.setState({
                avatar: base64Img,
                avatarInput: base64Img,
            }, () => {
                this.closeModalCropp();
            })
        } else {
            this.setState({
                cover: base64Img,
                coverInput: base64Img,
            }, () => {
                this.closeModalCropp();
            })
        }

    }

    render() {
        return (
            <div className="inscritionNextParts">
                {this.state.part1 == 1 && (
                    <div className="inscritionNextPart_1">
                        <h1>{this.state.trad.inscriptionTitle}</h1>

                        {this.state.cropperAvatar && (
                            <div className="modal">
                                <CropperAdv
                                    ratio={1}
                                    index={1}
                                    closeModalCropp={this.closeModalCropp}
                                    callBackCropper={this.callBackCropper}
                                />
                            </div>
                        )}
                        {this.state.cropperCover && (
                            <div className="modal">
                                <CropperAdv
                                    ratio={16 / 9}
                                    index={2}
                                    closeModalCropp={this.closeModalCropp}
                                    callBackCropper={this.callBackCropper}
                                />
                            </div>
                        )}

                        <h2>2/4</h2>
                        {this.state.message !== "" && (
                            <div style={{width: "100%", marginBottom: "20px"}}
                                 className={`message ${this.state.style}`}>
                                <p>{this.state.message}</p>
                            </div>
                        )}
                        <div className="imageBlock">
                            <div className="avatar">
                                <p className="avatarText">{this.state.trad.inscriptionAdPhoto}</p>
                                <div className="imgAvatar" onClick={this.handleOpenAvatar}>
                                    <p className="plusAvatar">+</p>
                                    <img src="" id="imgPreview1" alt=""/>
                                </div>
                                <input type="hidden" id="imageBase1" value={this.state.avatarInput}/>
                            </div>
                            <div className="cover">
                                <p className="coverText">{this.state.trad.inscriptionAdPhotoCover}</p>
                                <div className="imgCover" onClick={this.handleOpenCover}>
                                    <p className="plusCover">+</p>
                                    <img src="" id="imgPreview2" alt=""/>
                                </div>
                                <input type="hidden" id="imageBase2" value={this.state.coverInput}/>
                            </div>
                        </div>

                        <div className="nameBlock">
                            <label htmlFor="userName">{this.state.trad.labelUsername}*</label>
                            <input
                                type="text"
                                id="userName"
                                name="userName"
                                value={this.state.userName}
                                onChange={(e) => this.setState({userName: e.target.value, message: ""})}
                            />
                            <p className="obligatoire">*{this.state.trad.pObligatoire}</p>
                            <p></p>
                        </div>
                        <FormControlLabel
                            control={
                                <MuiSwitch
                                    size="small"
                                    checked={this.state.creator}
                                    onChange={this.handleChangeCreator}
                                    name="creator_mod"
                                />
                            }
                            label={`${
                                this.state.creator_mod ? this.state.trad.removeCreator : this.state.trad.becomeCreator
                            }`}
                        />
                        <div className="biographie">
                            <label htmlFor="biographieArea">{this.state.trad.labelBiographieArea}</label>
                            <textarea
                                name="biographieArea"
                                id="biographieArea"
                                cols="30"
                                rows="5"
                                maxLength="100"
                                onChange={(e) => this.handleChangeBio(e.target.value)}
                                value={this.state.biographie}
                            />
                            <p className="obligatoire">
                                {this.state.trad.charactersMax} ( {this.state.cara} /100 )
                            </p>
                        </div>

                        <div className="calendar">
                            <label htmlFor="name">{this.state.trad.labelAnniversaire}*</label>
                            <input type="date" max={this.state.currentDate} value={this.state.date} onChange={(e) => this.setState({date:e.target.value})}/>
                            <p className="obligatoire">*{this.state.trad.pObligatoire}</p>
                        </div>

                        <div className="form_submit">
                            <img
                                src={this.state.lang === "fr" ? continuerButton : continuerButtonen}
                                alt="continuer"
                                className="continuer"
                                onClick={() => this.handleInscription(1)}
                            />
                        </div>
                    </div>
                )}
                {this.state.part2 == 1 && (
                    <div className="inscritionNextPart_2">
                        <h1>{this.state.trad.inscriptionTitle}</h1>
                        <h2>3/4</h2>
                        {this.state.message !== "" && (
                            <div style={{width: "100%", marginBottom: "20px"}}
                                 className={`message ${this.state.style}`}>
                                <p>{this.state.message}</p>
                            </div>
                        )}
                        <div className="placeSearchbar">
                            <label htmlFor="searchbar">{this.state.trad.shooseSeats}</label>
                            <input
                                type="text"
                                id="searchbar"
                                name="searchbar"
                                value={this.state.searchInput}
                                onChange={(e) => this.setState({searchInput: e.target.value})}
                            />
                            <img
                                src={loupe}
                                onClick={this.searchResult}
                                className="loupeLogo"
                                alt="loupeLogo"
                            />
                        </div>

                        {/* /////////// PLACE DEBUT /////////// */}

                        <ul className="musicList">
                            {
                                this.state.places.map((value, index) => (
                                    <li
                                        key={index}
                                        className={
                                            this.state.follow.indexOf(index) == -1
                                                ? "musicStyleContainer"
                                                : "musicStyleContainerFollowed"
                                        }
                                        onClick={() => this.handleFollow(index)}
                                    >
                                        <div className="blockImg">
                                            <img className="imgStyle"
                                                 src={`${import.meta.env.VITE_BACKEND_URL_IMG}${value.image_place}`}/>
                                        </div>
                                        <div className="musicContent">
                                            <p className="musicStyle">{value.name}</p>
                                            <p className="location">{value.city} {value.country}</p>

                                            {this.state.follow.indexOf(index) == -1 ? (
                                                <p className="followButton">{this.state.trad.inscriptionPSuivre}</p>
                                            ) : (
                                                <p className="followedButton">{this.state.trad.inscriptionPSuivi}</p>
                                            )}
                                        </div>
                                    </li>
                                ))
                            }


                        </ul>

                        {/* /////////// PLACE FIN /////////// */}

                        <div className="passButton">
                            <p onClick={() => this.handleInscription(2)}>{this.state.trad.inscriptionTitlePass}</p>
                        </div>

                        <div className="form_submit">
                            <img
                                src={this.state.lang === "fr" ? continuerButton : continuerButtonen}
                                alt="continuer"
                                className="continuer"
                                onClick={() => this.handleInscription(2)}
                            />
                        </div>
                    </div>
                )}
                {this.state.part3 == 1 && (
                    <div className="inscritionNextPart_3">
                        <h1>{this.state.trad.inscriptionTitle}</h1>
                        <h2>4/4</h2>
                        {this.state.message !== "" && (
                            <div style={{width: "100%", marginBottom: "20px"}}
                                 className={`message ${this.state.style}`}>
                                <p>{this.state.message}</p>
                            </div>
                        )}
                        <div className="platformSelect">
                            <label htmlFor="platformSelect_select">
                                {this.state.trad.inscriptionLabelPlate}*
                            </label>
                            <select
                                id="platformSelect_select"
                                name="platformSelect_select"
                                value={this.state.selectedOption}
                                onChange={this.handleOptionChange}
                            >
                                {this.options.map((option) => (
                                    <option
                                        id="platformSelect_option"
                                        name="platformSelect_option"
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.text}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="otherSearchbar">
                            <label htmlFor="searchbar">{this.state.trad.inscriptionAutre}</label>
                            <input
                                type="text"
                                id="searchbar"
                                name="searchbar"
                                value={this.state.otherInput}
                                onChange={(e) => this.setState({otherInput: e.target.value})}
                            />
                        </div>

                        <div className="form_control_Container">
                            <div className="form_control">
                                <input
                                    type="checkbox"
                                    id="newsletter"
                                    name="newsletter"
                                    checked={this.state.newsletter ? "checked" : ""}
                                    onChange={(e) =>
                                        this.setState({newsletter: e.target.checked})
                                    }
                                />
                                <label htmlFor="newsletter">{this.state.trad.inscriptionLabelNewLetter}</label>
                            </div>
                        </div>

                        <div className="form_submit">
                            <img
                                src={this.state.lang === "fr" ? inscriptionEndButton : inscriptionEndButtonen}
                                alt="inscriptionEnd"
                                className="inscriptionEnd"
                                onClick={this.handleSave}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
